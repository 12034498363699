$( document ).ready(function() {

      $(".product").matchHeight();

      $.each($(".select-color"), function() {
        if(!$(this).hasClass("img-container")) {
          $(this).css('background-color', $(this).data("color"));
        }
      });


    	$(".fake-checkbox").click( function() {
          var group = $(this).data("group");
          if($(this).hasClass("exclude")) {
             $(".fake-checkbox."+group).not($(this)).removeClass("checked");
          }

          $(this).toggleClass("checked");  
       });

  	   $.each($(".img-container"), function() {
        		$(this).css('background-image', "url(" + $(this).data("src") + ")");
     		});

	     $(".img-detail-link").click( function(e) {
        e.preventDefault();
        var src = $(this).attr("href");
        $(".img-detail").css("background-image", "url(" + src + ")");
        $(".img-detail").data("zoom-image", src);
  	   });



      enquire.register("screen and (min-width:1024px)", {
          match : function() {
      	// var zoomable_options = { zoomWindowWidth: $("#add-to-cart-form").width() + 0, zoomWindowHeight: $("#add-to-cart-form").height() + 0, zoomWindowOffetx: 45, zoomWindowOffety: 0, borderSize: 1, borderColour: '#be926f' };
         $(".img-detail-link").click( function(e) {
            e.preventDefault();
            var src = $(this).attr("href");
            $(".img-detail").css("background-image", "url(" + src + ")");
            $(".img-detail").data("zoom-image", src);
            // $(".img-zoomable").elevateZoom( zoomable_options );
            $(".img-zoomable").elevateZoom({
      	   zoomType				: "lens",
      	  lensShape : "round",
      	  lensSize    : 250
      	  });
      	   });
      		   // $(".img-zoomable").elevateZoom( zoomable_options );
      		   // $(".img-zoomable").elevateZoom({
      		   // 	zoomType: "inner",
      		  	// cursor: "pointer"
      		   // });
      		$(".img-zoomable").elevateZoom({
      		   zoomType				: "lens",
      		  lensShape : "round",
      		  lensSize    : 250
      		  });
      	},
          unmatch : function() {},
          setup : function() {},
          deferSetup : true,
          destroy : function() {}
      });

});



