$( document ).ready(function() {
	$(".wrap_desktop").camera({
      loader: "none",
      navigation: false,
      fx: 'scrollHorz',
      pagination: true,
      height:'30%',
      autoAdvance: true,
      hover: false,
      playPause: false,
      time: 30000,
      alignment: 'topCenter'//,
      // pauseOnClick: false
   });  

   $(".wrap_mobile").camera({
      loader: "none",
      navigation: false,
      fx: 'scrollHorz',
      pagination: true,
      autoAdvance: true,
      hover: false,
      playPause: false,
      time: 30000,
      alignment: 'topCenter'//,
      // pauseOnClick: false
   }); 

  $.each($(".img-container"), function() {
      $(this).css('background-image', "url(" + $(this).data("src") + ")");
   });

    if($('#instafeed').length != 0)
    {
      var feed = new Instafeed({
          get: 'user',
          userId: '20723160',
          accessToken: '20723160.888a195.04f4a67c567f4b6e83ceb05fe76c1809',
          clientId: '888a1954789c442db4365e812abf8990',
          resolution: 'standard_resolution',
          sortBy: 'most-recent',
          limit: 6,
          template: '<div class="col-xs-6 col-sm-4"><a href="https://www.instagram.com/dmarandesign/"><img class="img-responsive instagram-img" src="{{image}}" alt="Instagram Dmaran"></a></div>',

      });
      feed.run();
   }

  if($(".sidebars").length != 0)
  {
    // All sides
    var sides = ["left", "top", "right", "bottom"];
    //$("h1 span.version").text($.fn.sidebar.version);
  
    // Initialize sidebars
    for (var i = 0; i < sides.length; ++i) {
        var cSide = sides[i];
        $(".sidebar." + cSide).sidebar({side: cSide});
    }

    // Click handlers
    $(".btn[data-action]").on("click", function () {
        var $this = $(this);
        var action = $this.attr("data-action");
        var side = $this.attr("data-side");
        $(".sidebar." + side).css("display","block");
        $(".sidebar." + side).trigger("sidebar:" + action);
        return false;
    });
  }
});